
  import { defineComponent, computed, ref, getCurrentInstance } from "vue"
  import { Segment } from "@/models/segments"
  import { shortDate } from "@/utils/helper"
  import { getKeycloak } from "@/plugins/keycloak"
  import { ResourceObject } from '@/models/jsonapi'
  import SchemaForm from "@/components/shared/form/SchemaForm.vue"
  import { segmentSchema, providerUiSegmentSchema } from "@/view_scripts/form_schemas/segment"
  import moment from 'moment'

  interface Props { segment: ResourceObject<string, Segment> }

  export default defineComponent({
    name: 'SegmentCard',
    components: { SchemaForm },
    props: {
      segment: {
        type: Object,
        required: true
      },
      editable: {
        type: Boolean,
        default: true
      }
    },
    setup(props: Props, { emit }) {
      const root = getCurrentInstance().proxy
      const showLoading = ref<Boolean>(false)
      const keycloakRef = getKeycloak()
      const role = keycloakRef.value.tokenParsed?.resource_access?.['pflegehilfe-senioren-platform']['roles'][0]
      const editSegmentModal = ref()
      const saveSegment = async (bvModalEvent: any) => {
        bvModalEvent.preventDefault()
        showLoading.value = true
        root.$store.dispatch('segments/edit', { id: props.segment.id, body: props.segment })
          .then( (r) => { emit('updated') })
          .finally(() => {showLoading.value = false})
        // refreshData()
        root.$nextTick(() => editSegmentModal.value.hide())
      }

      const dateString = (date:string|null) => {
        if (!date || date === '') return 'kein Datum'
        return moment(date).locale('de').format('L')
      }

      const saveVal = (val: number|null) => val === null ? 'NA' : val
      // const provPrice = computed(() => {
      //   const s = props.segment.attributes
      //   if (s) { return s.price + s.provPfs + s.provSeller } else {return 0}
      // })

      const uiSegmentSchema = providerUiSegmentSchema as any
      delete uiSegmentSchema.properties.attributes.properties.agEnd

      return {
        shortDate,
        role,
        // provPrice,
        editSegmentModal,
        segmentSchema,
        uiSegmentSchema,
        saveSegment,
        dateString,
        showLoading,
        saveVal
      }
    }
  })
