const cancelableDatetime = () => import('@/components/shared/CancelableDateTime.vue')
export const segmentSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/segment-model.json",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      title: "Attributes",
      properties: {
        start: { type: "string", title: "Von", format: "date" },
        end: { type: "string", title: "Bis", format: "date" },
        agStart: { type: "string", title: "Agentur Von", format: "date" },
        agEnd: { type: "string", title: "Agentur Bis", format: "date" },
        cgiArrival: { type: "string", title: "Anreise", format: "date" },
        cgiDeparture: { type: "string", title: "Abreise", format: "date" },
        price: { type: "number", title: "Preis"},
        provPfs: { type: "number", title: "Provision PfS", default: "99" },
        provSeller: { type: "number", title: "Provision VP", default: "200" },
        // provAgencyTotal: { type: "number", title: "Provision", default: "299" },
        discountPfs: { type: "number", title: "Monatsrabatt  PfS", default: "0" },
        discountSeller: { type: "number", title: "Monatsrabatt  VP", default: "0" },
        // discountCg: { type: "number", title: "Monatsrabatt", default: "0" },
        lumpDiscount: { type: "number", title: "Monatsrabatt Agentur", default: "0" },
        travelType: { type: "string", title: "Reisetyp" },
        travelCost: { type: "number", title: "Reisekosten" },
        dubHh: { type: "number", title: "2Pers. HH" },
        dubCr: { type: "number", title: "2PB HH" },
        nightCare: { type: "number", title: "Nachtpflege" },
        driversLicense: { type: "number", title: "Führerschein" },
        manualDuration: { type: "number", title: "Monatslänge" },
        clientPaid: { type: "boolean", title: "Kundenzahlung Erhalten" },
        agencyBillPaid: { type: "boolean", title: "Zahlung Erhalten" },
        sellerBillPaid: { type: "boolean", title: "Zahlung Getätigt" },
        note: { type: "string", title: "Notiz" },
      }
    }
  }
}

export const uiSegmentSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/segment-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        // start: {
        //   "ui:label": "Von",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0],
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/start"
        // },
        // end: {
        //   "ui:label": "Bis",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/end"
        // },
        // cgiArrival: {
        //   "ui:label": "Anreise",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiArrival"
        // },
        // cgiDeparture: {
        //   "ui:label": "Abreise",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiDeparture"
        // },
        // price: {
        //   "ui:label": "Preis",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/price",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        provPfs: {
          "ui:label": "Provision PfS",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/provPfs",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-3"]
        },
        provSeller: {
          "ui:label": "Provision VP",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/provSeller",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-3"]
        },
        // discountPfs: {
        //   "ui:label": "Einmaliger Rabatt PfS",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountPfs",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        discountSeller: {
          "ui:label": "Monatsrabatt VP",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountSeller",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-3"]
        },
        lumpDiscount: {
          "ui:label": "Einmaliger Rabatt VP",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/lumpDiscount",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-3"]
        },
        // dubHh: {
        //   "ui:label": "2 Personen HH",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/dubHh",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // dubCr: {
        //   "ui:label": "2 PB im HH",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/dubCr",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // nightCare: {
        //   "ui:label": "Nachtpflege",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/nightCare",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // driversLicense: {
        //   "ui:label": "Führerschein",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/driversLicense",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // travelType: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/travelType",
        //   "ui:classNames": ["col-6"]
        // },
        // travelCost: {
        //   "ui:label": "Reisekosten",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/travelCost",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6"]
        // },
        // clientPaid: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/clientPaid",
        //   "ui:label": "Kunde hat gezahlt",
        //   "ui:type": "checkbox",
        //   "ui:classNames": ["col-4"],
        //   "ui:options": { disabled: true }
        // },
        // agencyBillPaid: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/agencyBillPaid",
        //   "ui:label": "Zahlung Erhalten",
        //   "ui:type": "checkbox",
        //   "ui:classNames": ["col-4"]
        // },
        // sellerBillPaid: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/sellerBillPaid",
        //   "ui:label": "Zahlung Getätigt ",
        //   "ui:type": "checkbox",
        //   "ui:classNames": ["col-4"]
        // },
        note: {
          "ui:label": "Notiz",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/note",
          "ui:options": { disabled: true }
        },
      }
    }
  }
}

export const uiSegmentSchemaSeller = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/segment-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        // discountPfs: {
        //   "ui:label": "Einmaliger Rabatt PfS",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountPfs",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        discountSeller: {
          "ui:label": "Einmaliger Rabatt VP",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountSeller",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        // cgiArrival: {
        //   "ui:label": "Anreise",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiArrival"
        // },
        // cgiDeparture: {
        //   "ui:label": "Abreise",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiDeparture"
        // },
      }
    }
  }
}

export const providerUiSegmentSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/segment-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        // agStart: {
        //   "ui:label": "Von",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/agStart"
        // },
        // agEnd: {
        //   "ui:label": "Bis",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/agEnd"
        // },
        // cgiArrival: {
        //   "ui:label": "Anreise",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiArrival"
        // },
        // cgiDeparture: {
        //   "ui:label": "Abreise",
        //   "ui:options": {
        //     type: 'date',
        //     inTransform: (val: string) => val.split('T')[0]
        //   },
        //   "ui:classNames": ["col-6"],
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiDeparture"
        // },
        // price: {
        //   "ui:label": "Preis",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/price",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // provAgencyTotal: {
        //   "ui:label": "Provision",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/provAgencyTotal",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // travelCost: {
        //   "ui:label": "Reisekosten",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/travelCost",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // dubHh: {
        //   "ui:label": "2 Pers. HH",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/dubHh",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // dubCr: {
        //   "ui:label": "2 Senioren HH",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/dubCr",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // nightCare: {
        //   "ui:label": "Nachtpflege",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/nightCare",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        // driversLicense: {
        //   "ui:label": "Führerschein",
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/driversLicense",
        //   "ui:options": { type: "number" },
        //   "ui:classNames": ["col-6 col-md-4"]
        // },
        note: {
          "ui:label": "Notiz",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          "ui:options": {description:'Mitteilungen wie falsche Provision, Preis usw.'},
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/note"
        },
        clientPaid: {
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/clientPaid",
          "ui:label": "Kunde hat gezahlt",
          "ui:type": "checkbox",
          "ui:classNames": ["col-6"],
          "ui:options": {
            description: 'Deaktivierung entfernt Abschnitt von der Rechnung'
          }
        },
      }
    }
  }
}

export const adminUiSegmentSchema = {
  $schema: "https://json-schema.org/draft/2019-09/schema",
  $id: "https://crm.pflegehilfe-senioren.de/segment-view.json",
  $vocabulary: {
    "https://json-schema.org/draft/2019-09/vocab/core": true,
    "https://crm.pflegehilfe-senioren.de/ui-vocab": true
  },
  "ui:type": "form",
  "ui:widget": "b-row",
  type: "object",
  properties: {
    attributes: {
      type: "object",
      $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes",
      "ui:type": "group",
      "ui:label": "",
      properties: {
        start: {
          "ui:label": "Von",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/start"
        },
        end: {
          "ui:label": "Bis",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/end"
        },
        agStart: {
          "ui:label": "Von (Agentur)",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/agStart"
        },
        agEnd: {
          "ui:label": "Bis (Agentur)",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/agEnd"
        },
        cgiArrival: {
          "ui:label": "Anreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiArrival"
        },
        cgiDeparture: {
          "ui:label": "Abreise",
          "ui:options": {
            type: 'date',
            inTransform: (val: string) => val.split('T')[0]
          },
          "ui:classNames": ["col-6"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/cgiDeparture"
        },
        price: {
          "ui:label": "Preis",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/price",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        provPfs: {
          "ui:label": "Provision PfS",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/provPfs",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        provSeller: {
          "ui:label": "Provision VP",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/provSeller",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        discountPfs: {
          "ui:label": "Einmaliger Rabatt PfS",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountPfs",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        discountSeller:
          {
            "ui:label": "Einmaliger Rabatt VP",
            $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountSeller",
            "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
          },
        discountCg: {
          "ui:label": "Monatsrabatt",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/discountCg",
          "ui:options": {
            type: "number",
            description: "Gültig für diese BK"
          },
          "ui:classNames": ["col-6 col-md-4"]
        },
        dubHh: {
          "ui:label": "2 Personen HH",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/dubHh",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        dubCr: {
          "ui:label": "2 PB im HH",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/dubCr",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        nightCare: {
          "ui:label": "Nachtpflege",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/nightCare",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        driversLicense: {
          "ui:label": "Führerschein",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/driversLicense",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6 col-md-4"]
        },
        travelType: {
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/travelType",
          "ui:classNames": ["col-6"]
        },
        travelCost: {
          "ui:label": "Reisekosten",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/travelCost",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-6"]
        },
        manualDuration: {
          "ui:label": "Manuell Dauer",
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/manualDuration",
          "ui:options": { type: "number" },
          "ui:classNames": ["col-4"]
        },
        // agencyBillPaid: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/agencyBillPaid",
        //   "ui:label": "Agentur Rechnung gezahlt",
        //   "ui:type": "checkbox",
        //   "ui:classNames": ["col-4"],
        //   "ui:options": { disabled: true }
        // },
        // sellerBillPaid: {
        //   $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/sellerBillPaid",
        //   "ui:label": "Vertrieb Rechnung gezahlt ",
        //   "ui:type": "checkbox",
        //   "ui:classNames": ["col-4"],
        //   "ui:options": { disabled: true }
        // },
        clientPaid: {
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/clientPaid",
          "ui:label": "Kunde hat gezahlt",
          "ui:type": "checkbox",
          "ui:classNames": ["col-4"],
          "ui:options": {
            description: 'Deaktivierung entfernt Abschnitt von der Rechnung'
          }
        },
        note: {
          "ui:label": "Notiz",
          "ui:widget": "b-textarea",
          "ui:classNames": ["col-12"],
          $ref: "https://crm.pflegehilfe-senioren.de/segment-model.json#/properties/attributes/properties/note"
        },
      }
    }
  }
}